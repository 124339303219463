<template>
  <div class="stock-check">
    <titlebar :name="name" />

    <div class="page_box">
      <table
        width="100%"
        id="table"
        style="border-collapse: collapse"
        border="0"
        cellspacing="0"
        cellpadding="0"
      >
        <thead bgcolor="#F2F2F2">
          <tr
            bgcolor="#F2F2F2"
            class="thead-tr"
            style="height: 8rem;"
          >
            <td class="color-333 ft-24 text-left pd-lt30" style="width: 60%;">名称</td>
            <td class="color-333 ft-24 text-center">打印数量</td>
          </tr>
        </thead>

        <tbody v-if="good_list.length > 0" class="relative">
          <tr v-for="(item, index) in good_list" class="color-333 ft-24 text-center tbody-tr relative">
            <td class="text-left pd-lt30" style="width: 60%;">{{ item.name }}</td>
            <td class="text-center">
              <input v-if="platform_name == 'android'" class="stock-input" v-model="item.print_num" placeholder="请输入" readonly @click="openKeyboard(1, item, index)" />
              <input v-else class="stock-input" v-model="item.print_num" placeholder="请输入" type="number" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex flex-ac ft-26 all-print_num">
      <div>统一打印数量：</div>
      <input v-if="platform_name == 'android'" v-model="all_print_num" class="check-remark-input" readonly @click="openKeyboard(2, 'all_print_num')" />
      <input v-else v-model="all_print_num" class="check-remark-input" type="number" @input="handleChangeNum" />
    </div>
    <div class="foot_btn_box">
      <div class="ft-22 checkbox-box">
        <van-checkbox v-model="produce" icon-size="2.8rem" shape="square" class="mg-rt100">
          打印包装日期
          <div>：{{ produce_date }}<span class="text-underline mg-lt20 color-1588F5" @click.stop="changeDate">修改</span></div>
        </van-checkbox>
        <van-checkbox v-model="quality" icon-size="2.8rem" shape="square">打印保质期</van-checkbox>
      </div>
      <van-button class="foot_btn foot_submit" @click="beginPrintGood()">打印</van-button>
    </div>

    <!-- 包装日期选择 开始 -->
    <van-popup v-model="show_popup" round position="bottom" class="popup-picker-custom">
      <van-datetime-picker
        v-model="defaultDate"
        type="date"
        @confirm="onConfirm"
        @cancel="show_popup = false"
        item-height="8.5rem"
        :formatter="formatter"
      />
    </van-popup>
    <!-- 包装日期选择 结束 -->

    <van-number-keyboard 
      v-model="numVal" 
      :title="numVal" 
      :show="show_keyboard" 
      @blur="show_keyboard = false;" 
      @input="handleNumChange" 
      @delete="handleNumDelete"
      class="otherEatNum-nk" 
    />
  </div>
</template>
<script>
import titlebar from "@/views/component/titlebar";
import { datetimePickerFormatter, formatTime } from '../../utils/util';
export default {
  name: 'stock-check',
  components: {
    titlebar,
  },
  data() {
    return {
      name: 'toprintForGood',
      good_list: [],
      produce: false,
      quality: false,
      all_print_num: 1,
      produce_datetime: new Date(),
      produce_date: '今天',
      show_popup: false,
      formatter: datetimePickerFormatter,
      defaultDate: new Date(),
      maxdate: new Date(), //最大日期是当天
      numberType: '', // 数据类型
      numberModel: '', // 页面数据参数
      numberIndex: -1, // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
    }
  },
  computed: {
    printtag_good_list() {
      return this.$store.state.printtag_good_list;
    },
  },
  created() {
    this.good_list = this.printtag_good_list || [];
    this.getTagPrintSet();
  },
  methods: {
    handleNumChange(e) {
			this.numValArr.push(e);
			this.numVal = this.numValArr.join('');
      if (this.numberType == 1) {
        this.good_list[this.numberIndex].print_num = this.numVal;
      } else if (this.numberType == 2) {
        this[`${this.numberModel}`] = this.numVal;
        this.handleChangeNum();
      }
		},
		handleNumDelete(e) {
      this.numValArr.pop();
      this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
      if (this.numberType == 1) {
        this.good_list[this.numberIndex].print_num = this.numVal;
      } else if (this.numberType == 2) {
        this[`${this.numberModel}`] = this.numVal;
        this.handleChangeNum();
      }
		},
    openKeyboard(type, item, index) {
      console.log(item);
      this.numberType = type;
      if (type == 1) {
        this.numberIndex = index;
        this.numVal = item.print_num ? item.print_num.toString() : '';
        this.numValArr = item.print_num ? item.print_num.toString().split('') : [];
      } else if (type == 2) {
        this.numberModel = item;
        this.numVal = this[`${item}`] ? this[`${item}`].toString() : '';
        this.numValArr = this[`${item}`] ? this[`${item}`].toString().split('') : [];
      }
      this.show_keyboard = true;
    },
    getTagPrintSet() {
      this.$api.getTagPrintSet().then(res => {
        if (res.code == 1) {
          if (res.data.tag_print) {
            this.produce = res.data.tag_print.palin_produce == 1;
            this.quality = res.data.tag_print.palin_quality == 1;
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
    beginPrintGood() {
      for (let i = 0; i < this.good_list.length; i++) {
        const element = this.good_list[i];
        if (element.print_num <= 0) {
          this.$toast(`请输入${element.name}的打印数量`);
          return;
        }
        element['printType'] = '0';
        element['produce_time'] = this.produce ? formatTime(this.produce_datetime, 'yyyy年MM月dd日') : '';
        element['quality'] = this.quality ? element.quality : '';
      }
      let p_data = {
        type: 5,
        good_list: this.good_list,
      }
      this.$printFunc.printLabelReceipt(p_data);
    },
    handleChangeNum() {
      console.log(this.all_print_num);
      this.good_list.forEach(element => {
        this.$set(element, 'print_num', this.all_print_num);
      });
    },
    changeDate() {
      this.show_popup = true;
    },
    onConfirm(date) {
      // console.log(date);
      this.produce_datetime = date;
      if (this.isToday(this.produce_datetime)) {
        this.produce_date = '今天';
      } else {
        this.produce_date = formatTime(date, 'yyyy年MM月dd日');
      }
      this.show_popup = false;
    },
    isToday(date){
      var d0 = new Date();
      var y0 = d0.getFullYear(); 
      var m0 = d0.getMonth() + 1; 
      var d0 = d0.getDate(); 
      var d1 = date;
      var y1 = d1.getFullYear(); 
      var m1 = d1.getMonth() + 1; 
      var d1 = d1.getDate();
      return y0 == y1 && m0 == m1 && d0 == d1;
    }
  },
}
</script>
<style lang="less" scoped>
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.all-print_num {
  background: #fff;
  padding: 1rem 3rem 2rem;
  .check-remark-input {
    width: 16rem;
    height: 6rem;
    border: 0.2rem solid #DEDEDE;
    border-radius: 0.8rem;
    margin-left: 2.4rem;
    padding: 0 2rem;
    font-size: 2.4rem;
  }
}
  .stock-check {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .foot_btn_box {
    display: flex;
    justify-content: center;
    position: absolute;
    width: calc(100% - 6rem);
    bottom: 0;
    background: #fff;
    z-index: 10;
    height: 10rem;
    align-items: center;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
    left: 0;
    padding: 0 3rem;

    .foot_btn {
      font-size: 2.4rem;
      width: 42rem;
      height: 7rem;
      border-radius: 0.8rem;
      background: #eaeaea;
    }

    .foot_submit {
      color: #fff;
      background-color: #1588f5;
    }

    .checkbox-box {
      flex: 1;
      display: flex;
      /deep/.van-checkbox__label {
        display: flex;
      }
    }
  }

  .page_box {
    position: relative;
    height: calc(100% - 33rem);
    background: #fff;
    padding: 2rem 3rem;
    margin-top: 1.5rem;
    overflow-y: scroll;
    overflow-x: hidden;

    table {
      background: white;
      display: table;
      width: 100%;

      thead {
        width: 100%;
        display: block;
        display: table;
        table-layout: fixed;
      }

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
        vertical-align: middle;
      }
      
      tbody {
        display: block;
        width: 100%;
      }
      .tbody-tr {
        overflow-y: scroll;
        height: 8rem;
        width: 100%;
        border-bottom: 0.2rem rgba(0, 0, 0, 0.06) solid;
        &.odd {
          background: #fef7ef;
        }
        td {
          height: 8rem;
        }

        .stock-input {
          width: 16rem;
          height: 6rem;
          border: 0.2rem solid #DEDEDE;
          border-radius: 0.8rem;
          padding: 0 2rem;
          font-size: 2.4rem;
        }
      }
    }
  }
</style>